// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Form, FeatureRating, Feature, Anwendung, Farbe, Product } = initSchema(schema);

export {
  Form,
  FeatureRating,
  Feature,
  Anwendung,
  Farbe,
  Product
};