import React, {useState} from 'react';

// Aws Data Store and Model
import { DataStore } from 'aws-amplify';
import { Anwendung } from '../models';

import BreadcrumbsComponent from "../components/layout/BreadcrumbsComponent";
import Wrapper from "../components/layout/Wrapper";
import { useParams } from 'react-router';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ApplicationListItem from '../components/ApplicationListItem';
import ModalComponent from '../components/ModalComponent';
import ApplicationForm from '../forms/ApplicationForm';

export default function Applications({user, signOut}) {

  const {id} = useParams();

  // Fetch Products
  const [applications, setApplications] = useState([]);
	React.useEffect(() => {
		fetchApplications();
		async function fetchApplications() {
			try {
        const applications = await DataStore.query(Anwendung);
        setApplications(applications);
      } catch (error) {
        console.log("Error retrieving Applications", error);
      }
		}
      DataStore.observe(Anwendung).subscribe(() => {
        fetchApplications();
      }
    );
	}, []);  


  return (
    <Wrapper user={user} signOut={signOut}>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'80px'}}>
        <BreadcrumbsComponent />
        <ModalComponent
          buttonContent="neue Application hinzufügen"
          modalHeadline=""
        >
          <ApplicationForm />
        </ModalComponent>
      </div>
      <TableContainer style={{marginTop:'40px'}}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{backgroundColor:'#E30015'}}>
            <TableRow >
              <TableCell style={{color:'#fff', fontWeight:'700'}}>Anwendung</TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map((application) => (
              <ApplicationListItem 
                name={application.name}
                key={application.id}
                passedId={application.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}