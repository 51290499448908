import React from 'react';

// Aws Data Store and Model
import { DataStore } from 'aws-amplify';
import { Feature } from '../models';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';

export default function PropertiesListItem(
    {
        model,
        name,
        passedId
    }
) {

    async function handleDelete() {
		const modelToDelete = await DataStore.query(Feature, passedId);
        DataStore.delete(modelToDelete);
    }
    return (
        <TableRow style={{backgroundColor:'white'}}>
            <TableCell component="th" scope="row">
                <p style={{fontWeight:'800'}}>{name}</p>
            </TableCell>
            <TableCell align="right">
                
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"><Button onClick={handleDelete} style={{backgroundColor:'transparent', color:'rgb(227, 0, 21)'}}><DeleteForeverIcon /></Button></TableCell>
        </TableRow>
    )
}
