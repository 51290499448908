import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalComponent(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}
                style={{backgroundColor:'#E30015', color:'white', fontWeight:'700'}}
            >
                {props.buttonContent}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} style={{border:'2px solid rgb(230 230 230 / 99%)', backgroundColor:'white', width:'80vw', height:'80vh'}}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {props.modalHeadline}
                        </Typography>
                        <div>
                            {props.children}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
