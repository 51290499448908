import React, {useState} from 'react';

// Aws Data Store and Model
import { DataStore } from 'aws-amplify';
import { Farbe, Product } from '../models';

import BreadcrumbsComponent from "../components/layout/BreadcrumbsComponent";
import Wrapper from "../components/layout/Wrapper";
import { useParams } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ProductListItem from '../components/ProductListItem';
import ModalComponent from '../components/ModalComponent';
import ProductForm from '../forms/ProductForm';

export default function Products({user, signOut}) {
    const {id} = useParams();

    // Fetch Products
    const [product, setProduct] = useState([]);
	React.useEffect(() => {
		fetchProduct();
		async function fetchProduct() {
			try {
                const product = await DataStore.query(Product, id);
                setProduct(product);
            } catch (error) {
                console.log("Error retrieving Product", error);
            }
		}
        DataStore.observe(Product).subscribe(() => {
            fetchProduct();
        });
	}, [id]);

	return (
        <Wrapper user={user} signOut={signOut}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'80px'}}>
                <BreadcrumbsComponent />
                <ModalComponent
                    buttonContent="neue Produkt hinzufügen"
                    modalHeadline=""
                >
                    <ProductForm />
                </ModalComponent>
            </div>
                <TableContainer style={{marginTop:'40px'}}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead style={{backgroundColor:'#E30015'}}>
                            <TableRow >
                                <TableCell style={{color:'#fff', fontWeight:'700'}}>Werkstoffe</TableCell>
                                <TableCell align="left" style={{color:'#fff', fontWeight:'700'}}>Eigenschaften</TableCell>
                                <TableCell align="left" style={{color:'#fff', fontWeight:'700'}}>Anwendungen</TableCell>
                                <TableCell align="left" style={{color:'#fff', fontWeight:'700'}}>Farben</TableCell>
                                <TableCell align="left" style={{color:'#fff', fontWeight:'700'}}>Formen</TableCell>
                                <TableCell align="left" style={{color:'#fff', fontWeight:'700'}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {product.map((product) => (
                               <ProductListItem 
                                    model={product}
                                    name={product.name}
                                    productPassedId={product.id}
                                    key={product.id}
                               />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
    </Wrapper>
  );
}