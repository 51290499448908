import React, {useState} from 'react';
import { DataStore } from 'aws-amplify';
import { Product } from '../models';

import { useParams } from 'react-router';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TextField } from '@mui/material';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import iconProperty from '../assets/iconproperty.svg'
import iconEmptyState from '../assets/iconEmptyState.svg'
import Autocomplete from '@mui/material/Autocomplete';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ProductDetailModal(
    {
        model,
        update,
        handleDelete,
        color,
        application,
        featureRating,
        properties,
        forms
    }
) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [changeDetails, setChangeDetails] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

	let { id } = useParams();

    // Products
    const [ product, setProduct] = React.useState('');
    const [ name, setName ] = React.useState('');
	React.useEffect(() => {
        fetchProduct();
        async function fetchProduct() {
        const product = await DataStore.query(Product, model.id);
            setProduct(product);
            setName(product.name);
        }
    }, [id]);

    const updateProduct = () => {
        storeData();
        async function storeData() {
            const copy = Product.copyOf(product, (item) => {
                item.name = name;
            });
            await DataStore.save(copy);
            setReadOnly('true')
        }
    }

    return (
        <div key={model.id}>
            <Button onClick={handleOpen}
                style={{backgroundColor:'#E30015', color:'white', fontWeight:'700'}}
            >
                <ArrowForwardIosIcon />
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} style={{border:'2px solid rgb(230 230 230 / 99%)', backgroundColor:'white', width:'80vw', height:'80vh', position:'relative'}}>
                        <Box style={{marginTop:'20px', position:'absolute', bottom:'30px', right:'30px', display:'flex', gap:'20px'}}>
                            <Button onClick={handleDelete} style={{backgroundColor:'rgb(223 223 223)', color:'rgb(138 138 138)', fontWeight:'700', height:'40px'}}>
                                Löschen
                            </Button>
                            <Button
                                onClick={() => {
                                    setReadOnly(false)
                                    changeDetails && update()
                                    setChangeDetails((prevState) => !prevState)
                                }}
                                style={{backgroundColor:'rgb(7 113 36)', color:'white', fontWeight:'700', height:'40px'}}
                            >
                                {changeDetails ?
                                <p
                                    onClick={ () => {
                                        setReadOnly(true)
                                        handleClose()
                                        changeDetails && updateProduct()
                                    }}
                                    type="submit"
                                >
                                    Speichern
                                </p>
                                : <p>
                                    Bearbeiten
                                </p> }
                            </Button>
                        </Box>
                        <Box marginTop={5}>
                            <TextField id="standard-basic" label="Produktname" variant="standard" fullWidth
                                readOnly={true}
                                type="text"
                                name="name"
                                style={{fontWeight:'700'}}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Box>

                        <Box marginTop={5}>
                            <Typography style={{color:'rgb(227, 0, 21)', fontWeight:'600'}}>Eigenschaften:</Typography>
                            <div style={{display:'flex', gap:'50px'}}>
                                {featureRating.map(function (rating){
                                    const feature = properties.find((property) => property.id === rating.featureID)
                                    return(
                                        <div key={rating.id}>
                                            <p style={{fontWeight:'700', fontSize:'14px', margin:'0px', paddingTop:'5px'}}>{feature.name}</p>
                                                <StyledRating
                                                    readOnly={readOnly}
                                                    name="customized-color"
                                                    value={rating.rating}    
                                                    precision={1}
                                                    icon={<img src={iconProperty} className=''/>}
                                                    emptyIcon={<img src={iconEmptyState} className=''/>}
                                                />
                                        </div>
                                    )
                                })}
                            </div>
                        </Box>
                        <Box marginTop={5}>
                            <Typography style={{color:'rgb(227, 0, 21)', fontWeight:'600'}}>Farben:</Typography>
                            <div style={{display:'flex', gap:'10px', fontSize:'14px'}}>
                                <Autocomplete
                                    multiple
                                    readOnly={readOnly}
                                    id="tags-standard"
                                    options={color}
                                    value={color}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                        </Box>
                        <Box marginTop={5}>
                            <Typography style={{color:'rgb(227, 0, 21)', fontWeight:'600'}}>Applicationen:</Typography>
                            <div style={{display:'flex', gap:'10px', fontSize:'14px'}}>
                                <Autocomplete
                                    multiple
                                    readOnly={readOnly}
                                    id="tags-standard"
                                    options={application}
                                    value={application}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                        </Box>
                        <Box marginTop={5}>
                            <Typography style={{color:'rgb(227, 0, 21)', fontWeight:'600'}}>Formen:</Typography>
                            <div style={{display:'flex', gap:'10px', fontSize:'14px'}}>
                                <Autocomplete
                                    multiple
                                    readOnly={readOnly}
                                    id="tags-standard"
                                    options={forms}
                                    value={forms}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                        </Box>
                        <Box marginTop={5} style={{position:'absolute', bottom:'30px', right:'30px'}}>
                            
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
