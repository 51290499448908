import React from 'react'

import zero from '../assets/null.svg'
import one from '../assets/one.svg'
import two from '../assets/two.svg'
import three from '../assets/three.svg'
import four from '../assets/four.svg'
import five from '../assets/five.svg'


export default function RatingValues({value}) {

    const functionWithSwitch = (parameter, props ) => {
        var x = {value};

        switch(parameter)  {
            case 0:
                return <img src={zero} style={{padding:'0px'}} />
            case 1:
                return <img src={one} style={{padding:'0px'}} />
            case 2:
                return <img src={two} style={{padding:'0px'}} />
            case 3:
                return <img src={three} style={{padding:'0px'}} />
            case 4:
                return <img src={four} style={{padding:'0px'}} />
            case 5:
                return <img src={five} style={{padding:'0px'}} />
            default:
                return <p>HAllo</p>          }
        }
    
        return (
            <div>{functionWithSwitch(value)}</div>
        )
    }
    