import React, {useState} from 'react';

// Aws Data Store and Model
import { DataStore } from 'aws-amplify';
import { Feature } from '../models';

import BreadcrumbsComponent from "../components/layout/BreadcrumbsComponent";
import Wrapper from "../components/layout/Wrapper";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalComponent from '../components/ModalComponent';
import PropertiesListItem from '../components/PropertiesListItem';
import PropertyForm from '../forms/PropertyForm';

export default function Properties({user, signOut}) {

  // Fetch Products
  const [properties, setProperties] = useState([]);
	React.useEffect(() => {
		fetchProperties();
		async function fetchProperties() {
			try {
        const properties = await DataStore.query(Feature);
        setProperties(properties);
      } catch (error) {
        console.log("Error retrieving Product", error);
      }
		}
    DataStore.observe(Feature).subscribe(() => {
      fetchProperties();
    }
    );
	}, []);

  return (
    <Wrapper user={user} signOut={signOut}>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'80px'}}>
        <BreadcrumbsComponent />
        <ModalComponent
          buttonContent="neue Property hinzufügen"
          modalHeadline=""
        >
          <PropertyForm />
        </ModalComponent>
      </div>
      <TableContainer style={{marginTop:'40px'}}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{backgroundColor:'#E30015'}}>
            <TableRow >
              <TableCell style={{color:'#fff', fontWeight:'700'}}>Eigenschaften</TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {properties.map((property) => (
              <PropertiesListItem 
                name={property.name}
                key={property.id}
                passedId={property.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}