import { useTheme } from "@aws-amplify/ui-react";
import Logo from "./components/Logo";

export function Header() {
  const { tokens } = useTheme();

  return (
    <div style={{
        display:'flex',
        justifyContent:'center',
        width:'100%',
        marginBottom:'40px'
        }}>
        <Logo />
    </div>
  );
}
