import React, {useState} from 'react';

// Aws Data Store and Model
import { DataStore } from 'aws-amplify';
import { Product, Farbe, Anwendung, Feature, FeatureRating, Form } from '../models';

import { useParams } from 'react-router';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/system';

import ProductDetailModal from '../components/ProductDetailModal';
import ProductPropertyItem from './ProductPropertyItem';
import RatingValues from './RatingValues';

export default function ProductListItem(
    {
        name,
        productColorId,
        productPassedId,
        model
    }
) {

    const [readOnly, setReadOnly] = useState(true);
   
    async function handleDelete() {
		const modelToDelete = await DataStore.query(Product, productPassedId);
        DataStore.delete(modelToDelete);
    }

    const {id} = useParams();
    
    // Fetch Colors
    const [colors, setColors] = useState([]);
	React.useEffect(() => {
		fetchColors();
		async function fetchColors() {
			try {
                const colors = (await DataStore.query(Farbe)).filter(c => c.productID === productPassedId);
                setColors(colors);
                } catch (error) {
                console.log("Error retrieving Product", error);
            }
		}
        DataStore.observe(Farbe).subscribe(() => {
            fetchColors();
        });
	}, [id]);

    // Fetch Forms
    const [forms, setForms] = useState([]);
	React.useEffect(() => {
		fetchForms();
		async function fetchForms() {
			try {
                const forms = (await DataStore.query(Form)).filter(c => c.productID === productPassedId);
                setForms(forms);
                } catch (error) {
                console.log("Error retrieving Product", error);
            }
		}
        DataStore.observe(Form).subscribe(() => {
            fetchForms();
        });
	}, [id]);


    // Fetch Applications
    const [applications, setApplications] = useState([]);
	React.useEffect(() => {
		fetchApplications();
		async function fetchApplications() {
			try {
                const applications = (await DataStore.query(Anwendung)).filter(c => c.productID === productPassedId);
                setApplications(applications);
            } catch (error) {
                console.log("Error retrieving Product", error);
            }
		}
        DataStore.observe(Anwendung).subscribe(() => {
            fetchApplications();
        });
	}, [id]);


    // Fetch Property
    const [properties, setProperties] = useState([]);
    React.useEffect(() => {
        fetchProperty();
        async function fetchProperty() {
            try {
                const properties = (await DataStore.query(Feature));
                setProperties(properties);
            } catch (error) {
                console.log("Error retrieving Product", error);
            }
        }
        DataStore.observe(Feature).subscribe(() => {
            fetchProperty();
        });
    }, [id]);

    // Fetch Feature Rating
    const [featureRating, setFeatureRating] = useState([]);
    React.useEffect(() => {
        fetchFeatureRating();
        async function fetchFeatureRating() {
            try {
                const featureRating = (await DataStore.query(FeatureRating)).filter(c => c.productID === model.id);
                setFeatureRating(featureRating);
            } catch (error) {
                console.log("Error retrieving Product", error);
            }
        }
        DataStore.observe(FeatureRating).subscribe(() => {
            fetchFeatureRating();
        });
    }, [id]);

    return (
        <TableRow style={{backgroundColor:'white'}}>
            <TableCell component="th" scope="row">
                <p style={{fontWeight:'800'}}>{name}</p>
            </TableCell>
            <TableCell align="left">
                <Box style={{display:'flex', gap:'4px'}}>
                    {featureRating.map(function (rating){
                        const feature = properties.find((property) => property.id === rating.featureID)
                        return(
                            <div key={rating.id}>
                                <RatingValues value={rating.rating} />
                                <p style={{fontWeight:'700', padding:'0px', margin:'0px'}}>{feature.name}</p>
                            </div>
                        )
                    })}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box style={{display:'flex', gap:'4px'}}>
                    {applications.slice(0, 2).map((application) => (
                        <p key={application.id} style={{fontWeight:'700'}}>{application.name} <span style={{color:'rgb(227, 0, 21)'}}>/</span></p>
                    ))}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box style={{display:'flex', gap:'4px'}} >
                    {colors.slice(0, 2).map((color) => (    
                        <p key={color.id} style={{fontWeight:'700'}}>{color.name} <span style={{color:'rgb(227, 0, 21)'}}>/</span></p>
                    ))}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box style={{display:'flex', gap:'4px'}} >
                    {forms.slice(0, 2).map((form) => (    
                        <p key={form.id} style={{fontWeight:'700'}}>{form.name} <span style={{color:'rgb(227, 0, 21)'}}>/</span></p>
                    ))}
                </Box>
            </TableCell>
            <TableCell align="right">
                <ProductDetailModal 
                    model={model}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                    handleDelete={handleDelete}
                    color={colors}
                    application={applications}
                    featureRating={featureRating}
                    properties={properties}
                    forms={forms}
                />
            </TableCell>
        </TableRow>
    )
}
