import React, {useState} from 'react';

// Aws Data Store and Model
import { DataStore } from 'aws-amplify';
import { Farbe } from '../models';

import BreadcrumbsComponent from "../components/layout/BreadcrumbsComponent";
import Wrapper from "../components/layout/Wrapper";

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ColorListItem from '../components/ColorListItem';
import ModalComponent from '../components/ModalComponent';
import ColorForm from '../forms/ColorForm';

export default function Colors({user, signOut}) {

  // Fetch Products Color
  const [colors, setColors] = useState([]);
  React.useEffect(() => {
      // fetch all colors from aws amplify
      fetchColors();
      async function fetchColors() {
          try {
              const colors = await DataStore.query(Farbe);
              setColors(colors);
          } catch (error) {
                console.log("Error retrieving colors", error);
          }
      }
      DataStore.observe(Farbe).subscribe(() => {
        fetchColors();
      }
    );
  }, []);

  console.log(colors)

	return (
    <Wrapper user={user} signOut={signOut}>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'80px'}}>
        <BreadcrumbsComponent />
        <ModalComponent
          buttonContent="neue Farbe hinzufügen"
          modalHeadline=""
        >
          
          <ColorForm />
        </ModalComponent>
      </div>
      <TableContainer style={{marginTop:'40px'}}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{backgroundColor:'#E30015'}}>
            <TableRow >
              <TableCell style={{color:'#fff', fontWeight:'700'}}>Farbe</TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
              <TableCell align="right" style={{color:'#fff', fontWeight:'700'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {colors.map((color) => (
              <ColorListItem 
                name={color.name}
                model={color}
                key={color.id}
                passedId={color.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}