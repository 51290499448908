import React, {useState} from 'react';

// Aws Data Store and Model
import { DataStore } from 'aws-amplify';
import { Feature } from '../models';

// React Router
import { useNavigate, useParams } from 'react-router';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

// Create a New Color function
function onCreate() {
    DataStore.save(
        new Feature ({
            name:"",
        })
    );
}

export default function PropertyForm() {

    const [ property, setProperty ] = React.useState('');
    const {id} = useParams();
    const [fadeIn, setFadeIn] = useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        fetchColors();
        async function fetchColors() {
            const property = await DataStore.query(Feature);
            setProperty(property)
        }
    }, [id]);

    const [name, setName] = useState('');

    async function saveProperty() {
        const tempProperty = new Feature({
            name:name,
        });
        await DataStore.save(tempProperty);
    }

    function handleChange(event) {
        console.log(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        saveProperty().then((data) => {
            setFadeIn(true);
            setTimeout(() => {
                navigate('/', {replace: true});
            }, 1500);
        });
    }

    return (
        <form>
            <Box>
                <InputLabel htmlFor="component-simple" style={{color:'#E30015'}}>Name</InputLabel>
                <Input
                    style={{borderBottom:'2px solid white', width:'100%'}}
                    id="name"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </Box>
            <Box style={{marginTop:'20px'}}>
                <Button 
                    onClick={handleSubmit}
                    style={{backgroundColor:'#E30015', color:'white', fontWeight:'700'}}
                >
                    Speichern
                </Button>
            </Box>
        </form>
    )
}
