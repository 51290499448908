import React, {useState} from 'react';

import { DataStore } from 'aws-amplify';
import { Anwendung, Farbe, Product, Feature, FeatureRating, Form } from '../models';

import { useNavigate } from 'react-router';

// Material Design
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import iconProperty from '../assets/iconproperty.svg'
import iconEmptyState from '../assets/iconEmptyState.svg'

export default function ProductForm() {

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
          color: '#ff6d75',
        },
        '& .MuiRating-iconHover': {
          color: '#ff3d47',
        },
    });

    const [fadeIn, setFadeIn] = useState(false);
    const navigate = useNavigate();

    // Fetch Products Colors
    const [colors, setColors] = useState([]);
    React.useEffect(() => {
        // fetch all colors from aws amplify
        fetchColors();
        async function fetchColors() {
            try {
                const colors = await DataStore.query(Farbe);
                setColors(colors);
            } catch (error) {
                    console.log("Error retrieving colors", error);
            }
        }
        DataStore.observe(Farbe).subscribe(() => {
            fetchColors();
        });
    }, []);

    // Fetch Products Applications
    const [application, setApplication] = useState([]);
    React.useEffect(() => {
        // fetch all Application from aws amplify
        fetchApplication();
        async function fetchApplication() {
            try {
                const application = await DataStore.query(Anwendung);
                setApplication(application);
            } catch (error) {
                    console.log("Error retrieving colors", error);
            }
        }
        DataStore.observe(Anwendung).subscribe(() => {
            fetchApplication();
        });
    }, []);

    // Fetch Product Property
    const [properties, setProperties] = useState([]);
    const [featureRatings, setFeatureRatings] = React.useState([]);
    React.useEffect(() => {
        // fetch all Property from aws amplify
        fetchProperties();
        async function fetchProperties() {
            try {
                const properties = await DataStore.query(Feature);
                setProperties(properties);
            } catch (error) {
                    console.log("Error retrieving colors", error);
            }
        }
        DataStore.observe(Feature).subscribe(() => {
            fetchProperties();
        });
        properties.map(function (property){
            const newFeatureRating = new FeatureRating ({
                'rating': 0,
                'featureID': property.id,
                'productID': '',
            })
            setFeatureRatings(featureRatings.concat(newFeatureRating));
        });
    }, []);

    const [name, setName] = React.useState('');
    const [applicationID, setApplicationID] = React.useState([]);
    const [productID, setProductID] = React.useState([]);
    const [featureID, setFeatureID] = React.useState([]);
    const [rating, setRating] = React.useState('');

    async function saveProduct() {
        const product = await DataStore.save(
            new Product({
              name: name,
            })
        );
          
        await DataStore.save(
            new Farbe({
                name:name,
                productID: product.id,
            })
        );

        await DataStore.save(
            new Anwendung({
                name:name,
                productID: product.id,
            })
        );

        await DataStore.save(
            new Form({
                name:name,
                productID: product.id,
            })
        );

        await DataStore.save(
            new FeatureRating({
                rating:rating,
                productID: product.id,
                featureID: featureID,
            })
        );
    }

    function handleChange(event) {
        console.log(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        saveProduct().then((data) => {
            setFadeIn(true);
            setTimeout(() => {
                navigate('/', {replace: true});
            }, 1500);
        });
    }

    return (
        <Box>
            <h2>Produkt anlegen</h2>
            <Grid container spacing={2}>
                <Grid item xs={6} padding={1} style={{marginTop:'10px'}}>
                    <TextField 
                        id="standard-basic" 
                        label="Produktname" 
                        variant="standard" 
                        fullWidth
                        style={{borderBottom:'2px solid white', width:'100%'}}
                        name="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6} padding={1} style={{marginTop:'10px'}}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={colors}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Farben auswählen"
                                placeholder="Farbe auswählen"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} padding={1} style={{marginTop:'10px'}}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={application}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Application auswählen"
                                placeholder="Application auswählen"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} padding={1} style={{marginTop:'10px'}}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={application}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Application auswählen"
                                placeholder="Application auswählen"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} padding={3} style={{marginTop:'10px'}}>
                    <Grid container spacing={2}>
                        {featureRatings.map((featureRating) => (
                            <Grid item xs={3}>
                                <StyledRating
                                    name="customized-color"
                                    value={featureRating.rating}
                                    id={featureRating.featureID}
                                    precision={1}
                                    icon={<img src={iconProperty} className=''/>}
                                    emptyIcon={<img src={iconEmptyState} className=''/>}
                                    onChange={(event) => setRating(event.target.value)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Box style={{marginTop:'20px', position:'absolute', bottom:'30px', right:'30px'}}>
                <Button 
                    onClick={handleSubmit}
                    style={{backgroundColor:'#E30015', color:'white', fontWeight:'700'}}
                >
                    Produkt speichern
                </Button>
            </Box>
        </Box>
    )
}
