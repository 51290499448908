import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';

const BreadcrumbsComponent = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
     <div>
      {breadcrumbs.map(({
        match,
        breadcrumb
      }) => (
        <span key={match.pathname}>
          <NavLink to={match.pathname} style={{fontWeight:'700', textDecoration:'none', color:'rgb(227 0 21)'}}> {breadcrumb}</NavLink> <span style={{color:'rgb(227 0 21)'}}> / </span>
        </span>
      ))}
    </div>
  );
}

export default BreadcrumbsComponent;