import React from 'react'
import { useNavigate } from 'react-router-dom'
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import BuildIcon from '@mui/icons-material/Build';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ConstructionIcon from '@mui/icons-material/Construction';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import { Typography } from '@mui/material';

export default function Navigation({open, index}) {

    const navigate = useNavigate();

    return (
        <div>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                    onClick={() => navigate("/")}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:'white',
                        }}
                    >
                        {index % 2 === 0 ? <WebStoriesIcon /> : <WebStoriesIcon />}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <Typography style={{color:'white', fontWeight:'800'}}>Werkstoffe</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                    onClick={() => navigate("/applications")}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:'white',
                        }}
                    >
                        {index % 2 === 0 ? <ConstructionIcon /> : <ConstructionIcon />}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <Typography style={{color:'white', fontWeight:'800'}}>Anwendung</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                    onClick={() => navigate("/colors")}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:'white',
                        }}
                    >
                        {index % 2 === 0 ? <AutoFixHighIcon /> : <AutoFixHighIcon />}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <Typography style={{color:'white', fontWeight:'800'}}>Farben</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                    onClick={() => navigate("/properties")}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:'white',
                        }}
                    >
                        {index % 2 === 0 ? <BuildIcon /> : <BuildIcon />}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <Typography style={{color:'white', fontWeight:'800'}}>Eigenschaften</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    }}
                    onClick={() => navigate("/formen")}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:'white',
                        }}
                    >
                        {index % 2 === 0 ? <FormatAlignCenterIcon /> : <FormatAlignCenterIcon    />}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <Typography style={{color:'white', fontWeight:'800'}}>Formen</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </div>
    )
}
