// React Router
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Screens
import Home from "./screens/Home";

import awsExports from "./aws-exports";
import Products from "./screens/Products";
import Colors from "./screens/Colors";
import Properties from "./screens/Properties";
import Applications from "./screens/Applications";

// AWS Amplify
import { AmplifyProvider, } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Header } from "./AuthHeader";
import Formen from "./screens/Formen";

Amplify.configure(awsExports);

function App({ signOut, user }) {
  if (user) {
    return (
      <>  
        <AmplifyProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Products user={user} signOut={signOut} />} />
              <Route path="/colors" element={<Colors user={user} signOut={signOut} />} />
              <Route path="/properties" element={<Properties user={user} signOut={signOut} />} />
              <Route path="/applications" element={<Applications user={user} signOut={signOut} />} />
              <Route path="/formen" element={<Formen user={user} signOut={signOut} />} />
            </Routes>
          </Router>
        </AmplifyProvider>
      </>
    );
}
  return (<p>SignIn</p>);
}
export default withAuthenticator(App, {
  components: {
    Header,
  }
});